/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import TN_IMAGE from '../images/chatanooga.PNG';
import MESA_LOGO from '../images/Mesa-Associates-Transparent.png';
import ReactPlayer from "react-player"

import Navbar from '../eventLandingPage/Navbar';
import Hero from '../eventLandingPage/Hero';
import Highlights from '../eventLandingPage/Highlights';
import EventDetails from '../eventLandingPage/EventDetails';
import LandingFooter from '../eventLandingPage/Footer'; // renamed to avoid naming conflict

class publicMesaEventDetailed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                

                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                        <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h4 className="display-5 font-italic">The SDSIC Spring 2025 Live Event</h4>
                                        <h4>April 28th to May 1st 2025</h4>

                                        <p className="lead my-3">We are excited to be holding our only 2025 event this spring in beautiful Chattanooga, Tennesssee, hosted by our
                                            incredible partner, Mesa Associates, Inc. </p>
                                        <p className="lead mb-0">There will be a lot of details sent out as we plan this event but for now, just save the dates below!</p>
                                    </div>

                                </div>
                            </div>

                            {/** 1) New Landing Page Sections */}
                            <Navbar />
                            <Hero />
                            <Highlights />
                            <EventDetails />

                            <main role="main" className="container-fluid">
                                <div className="row">
                                    <div className="col-md-5">
                                        <h2 className="blog-post-title">Save the Date!</h2>
                                        <p>
                                            We are expecting another huge turnout once again as we only host one live event each year
                                            so don't forget to save April 28th to May 1st and we'll send out notifications when our registration goes live!
                                        </p>
                                        <p>
                                            Early bird price is active now until Jan 17th at $520. A $100 savings!
                                        </p>
                                        <h2>Hosted by: <a href="https://www.mesainc.com/" target="_blank"><img src={MESA_LOGO} alt="Mesa Associates" width="275" /></a></h2>
                                        <p>
                                            <h3 className="blog-post-title">The Venue</h3>
                                            <h5 className="mb-0">
                                                <a className="text-dark" href="https://www.marriott.com/reservation/rateListMenu.mi?dclid=CjkKEQiAuou6BhCuw__vm6zq9JABEiQAeI3GMXvm6IBx9dg1oBnCVn5CLCCGhnmuOK7l_8Zew-5qchLw_wcB&gclid=Cj0KCQiAuou6BhDhARIsAIfgrn6oIr5HegXLJoCvcWON_qN4u7ehLkVAcIVR5sqKTi9UC1iWLFy1bksaAtnqEALw_wcB" target="_blank">The Westin Chattanooga</a>
                                            </h5>
                                            <p className="card-text mb-auto">
                                                801 Pine Street, Chattanooga, TN 37402<br />
                                            </p>
                                            <br />
                                        </p>
                                        <p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-primary" href="/registration25">Register for this Event</a>
                                            </div>
                                        </div>
                                        </p>
                                    </div>
                                    <div className="col-md-7">

                                        <ReactPlayer
                                            url="https://youtu.be/rDJDQcq_vzE"
                                            playing="true"
                                            volume="0.5"
                                            width={650}
                                        // loop="true"
                                        // light="true"
                                        // controls="true"
                                        />

                                    </div>
                                </div>
                                <br />
                                <hr />

                                <div className="row">



                                    <div className="col-md-6">
                                        <div>
                                            <h2>Join Us!</h2>
                                        </div>
                                        <br />

                                        <p>
                                            Become a part of the SDSIC and gain access to a community dedicated to advancing the industry. As a free member, you will connect with leading professionals, stay informed on the latest trends,
                                            and collaborate on cutting-edge solutions. Enjoy exclusive access to networking opportunities, technical resources, and our annual conference, where you can share your expertise and learn
                                            from peers.
                                        </p>
                                        <p>
                                            Join today to enhance your career, contribute to the future of substation design, and be part of a thriving professional network.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-primary" href="/signup">Sign up for an SDSIC Membership here</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <div>
                                            <h2>Present at this Event!</h2>
                                        </div>
                                        <br />

                                        <p>
                                            Join us at this annual event, where the brightest minds in electric utility substation design and engineering come together to shape the future of our industry.
                                            We invite members to submit presentation proposals that highlight innovative projects, best practices, and emerging trends. This is your opportunity to share insights,
                                            showcase expertise, and contribute to meaningful discussions that drive progress in the field.
                                        </p>
                                        <p>
                                            Submit your proposal today and be a part of this essential event!
                                        </p>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-success" href="/conferencesubmission">Submit a Presentation</a>
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div>
                                            <h2>Want to be a Sponsor?</h2>
                                        </div>

                                        <br />


                                        <p>If you are interested in Sponsoring this event, check out our sponsorship page!</p>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <a className="btn btn-lg btn-block btn-primary" width="100%" href="/sponsorreg25">Register as a Sponsor</a>
                                            </div>
                                        </div>
                                        <br />
                                        <p>Or Download our event sponsorship prospectus below.</p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-success" href="/images/2025%20SDSIC%20Spring%20Event%20Sponsorship%20Packet_Final.pdf" target="_blank">Download sponsorship Prospectus </a>
                                            </div>
                                        </div>



                                    </div>


                                </div>
                            </main>



                        </div>

                    </div >

                </div >
                <br />
                <br />
                <br />
                <br />
                <Footer />
            </>
        )
    }
}

export default publicMesaEventDetailed