/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import TN_IMAGE from '../images/chatanooga.PNG';
import MESA_LOGO from '../images/Mesa-Associates-Transparent.png';
import ReactPlayer from "react-player"

class publicMesaEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h4 className="display-5 font-italic">The SDSIC Spring 2025 Live Event</h4>
                                        <h4>April 28th to May 1st 2025</h4>

                                        <p className="lead my-3">We are excited to be holding our only 2025 event this spring in beautiful Chattanooga, Tennesssee, hosted by our
                                            incredible partner, Mesa Associates, Inc. </p>
                                        <p className="lead mb-0">There will be a lot of details sent out as we plan this event but for now, just save the dates below!</p>
                                    </div>

                                </div>
                            </div>

                            <main role="main" className="container-fluid">


                                <div className="row">
                                    <div className="col-md-6">
                                        <h2 className="blog-post-title">Book your spot!</h2>
                                        <p>
                                            Spots are booking up fast with many members already taking advantange of early bird pricing
                                            for chatanooga - April 28th to May 1st! Don't miss out on this years ONLY SDSIC in person
                                            event.
                                        </p>
                                        <p>
                                            Pricing is now only $620 until April 7th!
                                        </p>
                                        <div className="row">
                                            {/* Left Column: Text */}
                                            <div className="col-md-6">


                                                <h2 className="text-dark">The Venue</h2>
                                                <h5 className="mb-0">
                                                    <a
                                                        className="text-dark"
                                                        href="https://www.marriott.com/en-us/hotels/chawi-the-westin-chattanooga/overview/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        The Westin Chattanooga
                                                    </a>
                                                </h5>

                                                <p className="card-text mb-auto">
                                                    801 Pine Street, Chattanooga, TN 37402
                                                </p>
                                            </div>

                                            {/* Right Column: Image */}
                                            <div className="col-md-6 text-center d-flex align-items-center justify-content-center">
                                                <h2>Hosted by:</h2>
                                                <a
                                                    href="https://www.mesainc.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={MESA_LOGO}
                                                        alt="Mesa Associates"
                                                        width="225"
                                                    />
                                                </a>
                                            </div>

                                        </div>
                                        <div>
                                            <br />
                                            <a className="btn btn-lg btn-block btn-primary" href="/registration25">
                                                Register for this Event
                                            </a>
                                        </div>


                                    </div>
                                    <div className="col-md-6">

                                        <ReactPlayer
                                            url="https://youtu.be/rDJDQcq_vzE"
                                            playing={true}
                                            volume={0.5}
                                            width={720}
                                        />
                                    </div>
                                </div>
                                <br />
                                <hr />

                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <div>
                                            <h2>Join Us!</h2>
                                        </div>
                                        <br />
                                        <p>
                                            Become a part of the SDSIC and gain access to a community dedicated to advancing
                                            the industry. As a free member, you will connect with leading professionals,
                                            stay informed on the latest trends, and collaborate on cutting-edge solutions.
                                            Enjoy exclusive access to networking opportunities, technical resources, and our
                                            annual conference, where you can share your expertise and learn from peers.
                                        </p>
                                        <p>
                                            Join today to enhance your career, contribute to the future of substation design,
                                            and be part of a thriving professional network.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-primary" href="/signup">
                                                    Sign up for an SDSIC Membership here
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div>
                                            <h2>Want to be a Sponsor?</h2>
                                        </div>
                                        <br />
                                        <p>If you are interested in Sponsoring this event, check out our sponsorship page!</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <a className="btn btn-lg btn-block btn-primary" width="100%" href="/sponsorreg25">
                                                    Register as a Sponsor
                                                </a>
                                            </div>
                                        </div>
                                        <br />
                                        <p>Or Download our event sponsorship prospectus below.</p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a
                                                    className="btn btn-lg btn-block btn-success"
                                                    href="/images/2025%20SDSIC%20Spring%20Event%20Sponsorship%20Packet_Final.pdf"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Download Sponsorship Prospectus
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className="row mt-5 text-center">
                                    <div className="col-md-8">
                                        <h2>Want to be a Sponsor?</h2>
                                        <p>
                                            If you are interested in Sponsoring this event, check out our sponsorship page!
                                            Sponsorship provides excellent exposure at our in-person event as well as
                                            to our 2,300+ members via our community website.
                                        </p>

                                        {/* CTA Buttons in a single row */}
                                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3 mt-4">
                                            <a
                                                className="btn btn-lg btn-primary"
                                                href="/sponsorreg25"
                                            >
                                                Register as a Sponsor
                                            </a>

                                            <a
                                                className="btn btn-lg btn-success"
                                                href="/images/2025%20SDSIC%20Spring%20Event%20Sponsorship%20Packet_Final.pdf"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Download Sponsorship Brochure
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h2>Our Socials!</h2>
                                        <p>Follow us on social media and keep up to date with all announcements.</p>

                                        <div className="d-flex justify-content-center gap-3 mt-4">
                                            <a
                                                data-mdb-ripple-init
                                                className="btn btn-lg btn-primary"
                                                href="https://www.linkedin.com/company/sds-industry-consortium"
                                                role="button"
                                            >
                                                <i className="fab fa-linkedin-in fa-2x me-2"></i>
                                                LinkedIn
                                            </a>

                                            <a
                                                data-mdb-ripple-init
                                                className="btn btn-lg btn-primary"
                                                href="https://www.instagram.com/sdsic_2025/"
                                                role="button"
                                            >
                                                <i className="fab fa-instagram fa-2x me-2"></i>
                                                Instagram
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <hr />
                                <div className="row mt-5">
                                    <div className="col-md-12 text-center">
                                        <h2>The Event Highlights</h2>

                                        <p>
                                            Once again this year, we’re bringing together the best and
                                            brightest minds in substation design, engineering, and innovation. Here’s a quick
                                            overview of what you can expect at our 2025 conference:
                                        </p>
                                    </div>
                                </div>

                                {/* Stats Section */}
                                <div className="row text-center">
                                    <div className="col-md-3">
                                        <h3>400+</h3>
                                        <p>Attendees</p>
                                    </div>
                                    <div className="col-md-3">
                                        <h3>70+</h3>
                                        <p>Speakers</p>
                                    </div>
                                    <div className="col-md-3">
                                        <h3>12+</h3>
                                        <p>Hours of Networking</p>
                                    </div>
                                    <div className="col-md-3">
                                        <h3>70+</h3>
                                        <p>Interactive Sessions</p>
                                    </div>
                                </div>

                                <hr />

                                {/* Who Attended Section */}
                                <div className="row my-5">
                                    <div className="col-md-6">
                                        <h2>Who Attends?</h2>
                                        <p>
                                            Our annual event draws a wide range of professionals from across the substation
                                            design and engineering industry, including:
                                        </p>
                                        <ul>
                                            <li>Utility Engineers &amp; Technicians</li>
                                            <li>CAD Designers &amp; Drafters</li>
                                            <li>Services Providers &amp; Vendors</li>
                                            <li>Consultants &amp; Analysts</li>
                                            <li>Industry Thought Leaders</li>
                                        </ul>
                                        <p>
                                            From entry-level engineers to seasoned experts, this gathering is the perfect
                                            opportunity to forge new connections, learn from your peers, and discover the
                                            latest developments shaping the future of substation design.
                                        </p>
                                    </div>

                                    {/* Speakers Section */}
                                    <div className="col-md-6">
                                        <h2>Meet Our Speakers</h2>
                                        <p>
                                            Our lineup of speakers includes industry-leading professionals, innovators, and
                                            thought leaders eager to share their expertise and insights. Stay tuned for our
                                            full speaker lineup. In the meantime, here’s a sneak peek of what you can expect:
                                        </p>
                                        <ul>
                                            <li>Keynotes from top utility executives</li>
                                            <li>Case studies on successful substation design projects</li>
                                            <li>Hands-on technology demonstrations and workshops</li>
                                            <li>Panel discussions on industry trends and challenges</li>
                                        </ul>
                                        {/* <p>
                                            <a className="btn btn-primary" href="/speakerlist25">
                                                View Full Speaker List
                                            </a>
                                        </p> */}
                                    </div>
                                </div>
                                <hr />
                                {/* Additional Networking Highlights */}
                                <div className="row my-5">
                                    <div className="col-md-12">
                                        <h2 className="text-center">Join Us!</h2>
                                        <p className="text-center">
                                            Become a part of the SDSIC and gain access to a community dedicated to advancing the industry. As a free member, you will connect with leading professionals, stay informed on the latest trends, and collaborate on cutting-edge solutions. Enjoy exclusive access to networking opportunities, technical resources, and our annual conference, where you can share your expertise and learn from peers.
                                        </p>
                                        <p className="text-center">
                                            Join today to enhance your career, contribute to the future of substation design, and be part of a thriving professional network.
                                        </p>
                                        <p className="text-center">
                                            <div className="row">

                                                <a className="btn btn-lg btn-block btn-primary" href="/signup">
                                                    Sign up for a Free SDSIC Membership here
                                                </a>

                                            </div>
                                        </p>
                                    </div>


                                </div>
                                <hr />
                            </main>
                        </div>
                    </div >
                </div >
                <br />
                <br />
                <Footer />
            </>
        )
    }
}

export default publicMesaEvent