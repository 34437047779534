import React from 'react';
import './Hero.css'; // optional for your hero styling

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>SDSIC Spring 2025</h1>
        <p>Join us April 28th – May 1st in Chattanooga, TN for our only 2025 live event!</p>
        <a href="/registration25" className="hero-button">Register Now</a>
      </div>
    </section>
  );
}

export default Hero;
