import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { signout, isAuthenticated } from '../auth';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';


const isActive = (history, path) => {
    if (history.location.pathname === path) return { color: '#3067ba' };
    else return { color: 'white' };
};

const Menu = ({ history }) => (

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">

                {!isAuthenticated() && ( 
                    <React.Fragment>
                        <Nav.Link style={isActive(history, '/')} href="/">Home</Nav.Link>
                        {/* <Link className="p-2 text-white" style={isActive(history, '/')} to="/">Home</Link> */}
                        {/* <Link className="p-2 text-muted" style={isActive(history, '/about')} to="/about">About Us</Link> */}

                        
                       
                        {/* <Link className="p-2 text-muted" style={isActive(history, '/events')} to="/events">Our Events</Link> */}
                        <NavDropdown title="Events" id="basic-nav-dropdown">
                            {/* <NavDropdown.Item style={isActive(history, '/publicevent2022')} to="/publicevent2022">Another action</NavDropdown.Item> */}
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/publicMesaEvent')} to="/publicMesaEvent">2025 Live Event</Link></NavDropdown.Item>                            
                            {/* <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/publicVirtual23')} to="/publicVirtual23">Fall Virtual 2023</Link></NavDropdown.Item> */}
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/events')} to="/events">Past Events</Link></NavDropdown.Item>
                            {/* <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/publicCallforPapers')} to="/publicCallforPapers">Call for Presentations</Link></NavDropdown.Item> */}
                        </NavDropdown>
                        {/* <Link className="p-2 text-muted" style={isActive(history, '/survey2022')} to="/survey2022">Survey</Link>
                        <Link className="p-2 text-white" style={isActive(history, '/sponsors')} to="/sponsors">Our Sponsors</Link>
                        <Link className="p-2 text-white" style={isActive(history, '/signin')} to="/signin">Sign In</Link>
                        <Link className="p-2 text-white" style={isActive(history, '/signup')} to="/signup">Sign Up</Link> */}

                            

                        <NavDropdown title="About Us" id="basic-nav-dropdown">
                            {/* <NavDropdown.Item style={isActive(history, '/publicevent2022')} to="/publicevent2022">Another action</NavDropdown.Item> */}
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/memberLogos')} to="/memberLogos">Our Members</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/about')} to="/about">Our Team</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/awards')} to="/awards">Our Awards</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/sdsicmerch')} to="/sdsicmerch">Merchandise</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/podcast')} to="/podcast">PowerUp Podcast</Link></NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link style={isActive(history, '/sponsors')} href="/sponsors">Our Sponsors</Nav.Link> */}
                        {/* <Nav.Link style={isActive(history, '/podcast')} href="/podcast">Our Podcast</Nav.Link> */}
                        <Nav.Link style={isActive(history, '/signin')} href="/signin">Sign In</Nav.Link>
                        <Nav.Link style={isActive(history, '/signup')} href="/signup">Sign Up</Nav.Link>
                    </React.Fragment>
                )}
            </Nav>

            <Nav>
                {isAuthenticated() && (
                    <React.Fragment>
                        <Nav.Link style={isActive(history, '/usershome')} href="/usershome">Home</Nav.Link>
                                                        
                        {/* <Link className={history.location.pathname === '/users' ? 'active p-2 text-muted' : 'not-active p-2 text-muted'} to="/users">Members</Link> */}
                        
                        <Nav.Link style={isActive(history, '/grouplist')} href="/grouplist">Committees</Nav.Link>
                        <Nav.Link style={isActive(history, '/allevents')} href="/allevents">Committee Events</Nav.Link>

                        <Nav.Link style={isActive(history, '/findpeople')} href="/findpeople">Members</Nav.Link>
                        {/* <Nav.Link style={isActive(history, '/sponsors')} href="/sponsors">Our Sponsors</Nav.Link> */}
                        
                        {/* <Nav.Link style={isActive(history, '/podcast')} href="/podcast">Our Podcast</Nav.Link> */}

                        <NavDropdown title="Events" id="basic-nav-dropdown">
                            {/* <NavDropdown.Item style={isActive(history, '/publicevent2022')} to="/publicevent2022">Another action</NavDropdown.Item> */}
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/publicMesaEvent')} to="/publicMesaEvent">2025 - Chattanooga</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/655bb358b24d7dfa488b46af')} to="/showcaseEvent/655bb358b24d7dfa488b46af">2024 - Kansas City</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/655b7faed1c936a37548aad9')} to="/showcaseEvent/655b7faed1c936a37548aad9">2023 - Fall Virtual</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/6380ae5f12188c63625e632d')} to="/showcaseEvent/6380ae5f12188c63625e632d">2023 - Nashville</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/6261a6116b020bed17a90d48')} to="/showcaseEvent/6261a6116b020bed17a90d48">2022 - Fall</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/62054cf16f4c6a6808384c1e')} to="/showcaseEvent/62054cf16f4c6a6808384c1e">2022 - Spring</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/613f4a91fc90ef699581b27d')} to="/showcaseEvent/613f4a91fc90ef699581b27d">2021 - Fall</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/showcaseEvent/6001c0547024a75b1a789ab1')} to="/showcaseEvent/6001c0547024a75b1a789ab1">2021 - Spring</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/events')} to="/events">Live Event Photos</Link></NavDropdown.Item>
                            {/* <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/publicCallforPapers')} to="/publicCallforPapers">Call for Presentations</Link></NavDropdown.Item> */}
                        </NavDropdown>

                        <NavDropdown title="About Us" id="basic-nav-dropdown">
                            {/* <NavDropdown.Item style={isActive(history, '/publicevent2022')} to="/publicevent2022">Another action</NavDropdown.Item> */}
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/memberLogos')} to="/memberLogos">Our Members</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/about')} to="/about">Our Team</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/awards')} to="/awards">Our Awards</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/sdsicmerch')} to="/sdsicmerch">Merchandise</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link className="p-2 text-muted" style={isActive(history, '/podcast')} to="/podcast">PowerUp Podcast</Link></NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link style={isActive(history, '/podcast')} href="/podcast">Podcast</Nav.Link>
                        <Nav.Link style={isActive(history, '/sdsicmerch')} href="/sdsicmerch">Store</Nav.Link>
                        {/* <Link to={`/publicevent2022`} style={isActive(history, '/publicevent2022')} className="p-2 text-muted">2022 Events</Link> */}
                        {/* <Link to="/survey2022" style={isActive(history, '/survey2022')} className="p-2 text-muted">Survey</Link> */}
                        
                        <Nav.Link 
                            style={isActive(history, `/user/${isAuthenticated() && isAuthenticated().userId}`)}
                            href={`/user/${isAuthenticated() && isAuthenticated().userId}`}>
                                {`${isAuthenticated().name}'s profile`}
                        </Nav.Link>

                        {/* <Link
                            to={`/user/${isAuthenticated() && isAuthenticated().userId}`}
                            style={isActive(history, `/user/${isAuthenticated() && isAuthenticated().userId}`)}
                            className="p-2 text-muted"
                        >
                            {`${isAuthenticated().name}'s profile`}
                        </Link> */}
                        {/* <Link to={`/post/create`} style={isActive(history, `/post/create`)} className="p-2 text-muted">Create Post</Link> */}


                        <span
                            className="p-2 text-white"
                            style={{ cursor: 'pointer', color: '#fff' }}
                            onClick={() => signout(() => history.push('/'))}>Sign Out
                        </span>

                        {/* {isAuthenticated() && isAuthenticated().userId === "5e2611483f99fa3bb4dd922a" && (
                            // <Link to={`/admin`} style={isActive(history, `/admin`)} className="p-2 text-muted">Admin</Link>
                            <Nav.Link 
                                style={isActive(history, `/admin`)}
                                href={'/admin'}>
                                    {`${isAuthenticated().name}'s profile`}
                             </Nav.Link>

                        )} */}

                    </React.Fragment>
                )}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    


);

export default withRouter(Menu);