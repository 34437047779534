import React, { Component } from 'react'
import { listPresentationsByGroup } from './apigroup';
import { isAuthenticated } from '../auth';
import Card from 'react-bootstrap/Card'
// import Badge from 'react-bootstrap/Badge'
// import Button from 'react-bootstrap/Button'
// import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';


class PresentationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            presentations21: [],
            presentations22: [],
            presentations23: [],
            presentations24: [],
            presentation25: [],
            groupId: this.props.groupId,
            eventId: ''       
        };
    }


    token = isAuthenticated() && isAuthenticated().token;
    
    list2021Presentations = (groupId) => { 
          
        listPresentationsByGroup(groupId, isAuthenticated() && isAuthenticated().token, '2021').then(data => {        
            
            if (!data) {     
                    
                this.setState({
                    presentations21: data
                });
            } else {
               
                this.setState({
                    presentations21: data
                });
            }
        });
    };

    list2022Presentations = (groupId) => { 
          
        listPresentationsByGroup(groupId, isAuthenticated() && isAuthenticated().token, '2022').then(data => {        
            
            if (!data) {     
                    
                this.setState({
                    presentations22: data
                });
            } else {
               
                this.setState({
                    presentations22: data
                });
            }
        });
    };

    list2023Presentations = (groupId) => { 
          
        listPresentationsByGroup(groupId, isAuthenticated() && isAuthenticated().token, '2023').then(data => {        
            
            if (!data) {     
                    
                this.setState({
                    presentations23: data
                });
            } else {
               
                this.setState({
                    presentations23: data
                });
            }
        });
    };

    list2024Presentations = (groupId) => { 
          
        listPresentationsByGroup(groupId, isAuthenticated() && isAuthenticated().token, '2024').then(data => {        
            
            if (!data) {     
                    
                this.setState({
                    presentations24: data
                });
            } else {
               
                this.setState({
                    presentations24: data
                });
            }
        });
    };

    list2025Presentations = (groupId) => { 
          
        listPresentationsByGroup(groupId, isAuthenticated() && isAuthenticated().token, '2025').then(data => {        
            
            if (!data) {     
                    
                this.setState({
                    presentations25: data
                });
            } else {
               
                this.setState({
                    presentations25: data
                });
            }
        });
    };



    setEventId(groupId) {
        
        switch (groupId) {
            case '5e2a5e764175b97d84ec6cae':
                //physical
                return '6001d4e256a94550905723b1'
            case '5e44050f4e6ed37ab467d0f2':
                //electrical
                return  '61e998f4f5b727e70e24bfef'                 
            case '5e44052e4e6ed37ab467d0f3':
                //Technology
                return  '6001d42856a94550905723ae'               
            case '5e4405864e6ed37ab467d0f4':
                //civil
                return '61e9992cf5b727e70e24bff0'               
            case '5e4406454e6ed37ab467d0f5':
                //bizstrategy
                return '6001d4b056a94550905723b0' 
            case '5e5591a97ff73f6d34835a05':
                // leadership
                return '63a331bc377a80f363a8073d'
            case '639242d4971a30726884fd85':
                // innvoative solutons
                return '640f65606164bc23c3f3eb4e'
            default :
                return '';                           
          };

    };

    componentDidMount() {        
        this.list2021Presentations(this.setEventId(this.props.groupId));
        this.list2022Presentations(this.setEventId(this.props.groupId));
        this.list2023Presentations(this.setEventId(this.props.groupId));
        this.list2024Presentations(this.setEventId(this.props.groupId));
        this.list2025Presentations(this.setEventId(this.props.groupId));        
    }


    render21Files = (presentations21, groupId) => {
        return (
            <div>
                {presentations21 && (presentations21.length > 0) && presentations21.map((presentation, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{presentation.title}</b>
                            </Card.Header>
                            <Card.Body>
                                {presentation.description}
                                <br/>
                                <a href={`/presentation/${presentation._id}`} >Launch video</a>                                
                            </Card.Body>
                            
                        </Card>
                    )
                })}
                  {presentations21 && <h4>No 2021 recordings found</h4>}
            </div>
        )
    }

    render22Files = (presentations22, groupId) => {
        return (
            <div>
                {presentations22 && (presentations22.length > 0) && presentations22.map((presentation, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{presentation.title}</b>
                            </Card.Header>
                            <Card.Body>
                                {presentation.description}
                                <br/>
                                <a href={`/presentation/${presentation._id}`} >Launch video</a>                                
                            </Card.Body>
                            
                        </Card>
                    )
                })}
                  {!presentations22 && <h4>No 2022 recordings found</h4>}
            </div>
        )
    }

    render23Files = (presentations23, groupId) => {
        return (
            <div>
                {presentations23 && (presentations23.length > 0) && presentations23.map((presentation, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{presentation.title}</b>
                            </Card.Header>
                            <Card.Body>
                                {presentation.description}
                                <br/>
                                <a href={`/presentation/${presentation._id}`} >Launch video</a>                                
                            </Card.Body>
                            
                        </Card>
                    )
                })}
                  {!presentations23 && <h4>No 2023 recordings found</h4>}
            </div>
        )
    }

    render24Files = (presentations24, groupId) => {
        return (
            <div>
                {presentations24 && (presentations24.length > 0) && presentations24.map((presentation, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{presentation.title}</b>
                            </Card.Header>
                            <Card.Body>
                                {presentation.description}
                                <br/>
                                <a href={`/presentation/${presentation._id}`} >Launch video</a>                                
                            </Card.Body>
                            
                        </Card>
                    )
                })}
                  {!presentations24 && <h4>No 2024 recordings found</h4>}
            </div>
        )
    }

    render25Files = (presentations25, groupId) => {
        return (
            <div>
                {presentations25 && (presentations25.length > 0) && presentations25.map((presentation, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{presentation.title}</b>
                            </Card.Header>
                            <Card.Body>
                                {presentation.description}
                                <br/>
                                <a href={`/presentation/${presentation._id}`} >Launch video</a>                                
                            </Card.Body>
                            
                        </Card>
                    )
                })}
                  {!presentations25 && <h4>No 2025 recordings found</h4>}
            </div>
        )
    }

    render() {
        const { presentations21, presentations22, presentations23, presentations24, presentations25, groupId } = this.state;
        return (
            <>
                <h4 className="font-italic">2025 Monthly Recordings</h4>
                {this.render25Files(presentations25, groupId)}
                <hr/>
                <h4 className="font-italic">2024 Monthly Recordings</h4>
                {this.render24Files(presentations24, groupId)}
                <hr/>
                <h4 className="font-italic">2023 Monthly Recordings</h4>
                {this.render23Files(presentations23, groupId)}
                <hr/>
                <h4 className="font-italic">2022 Monthly Recordings</h4>
                {this.render22Files(presentations22, groupId)}
                <hr/>
                <h4 className="font-italic">2021 Monthly Recordings</h4>
                {this.render21Files(presentations21, groupId)}
            </>
        )
    };
};

export default PresentationList;