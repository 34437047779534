import React, { useState } from 'react';
import { Tab, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import './stripe.css';
import { isAuthenticated } from '../auth';
import { sendMessage } from '../user/apiUser';
import { creatNewProposalSubmission } from './apiEvents';

import moment from "moment";

function ConferencePresentationSubmission() {
    const [key, setKey] = useState('speaker');
    const [error, setError] = useState('');

    // Form Fields
    const [jobTitle, setJobTitle] = useState('');
    const [speakerFirstName, setSpeakerFirstName] = useState(isAuthenticated().name);
    const [speakerLastName, setSpeakerLastName] = useState(isAuthenticated().lastName);
    const [speakerEmail, setSpeakerEmail] = useState(isAuthenticated().email);
    const [bio, setBio] = useState('');
    const [mobile, setMobile] = useState('');
    const [company, setCompany] = useState('');
    const [companyType, setCompanyType] = useState('')
    const [country, setCountry] = useState('');
    const [primarySpeaker, setPrimarySpeaker] = useState('');
    const [onBehalfOf, setOnBehalfOf] = useState('');
    const [firstTime, setFirstTime] = useState('');
    const [experience, setExperience] = useState('');
    const [proposalTitle, setProposalTitle] = useState('');
    const [proposalDesc, setProposalDesc] = useState('');
    const [proposalFormat, setProposalFormat] = useState('');
    const [learn1, setLearn1] = useState('');
    const [learn2, setLearn2] = useState('');
    const [learn3, setLearn3] = useState('');
    const [committee1, setCommittee1] = useState('');
    const [committee2, setCommittee2] = useState('');
    const [audienceRole, setAudienceRole] = useState('');
    const [backGround, setBackGround] = useState('');

    const token = isAuthenticated() && isAuthenticated().token;

    const charLimit = {
        bio: 400,
        proposalTitle: 100,
        proposalDesc: 800,
    };

    // Function to validate the mobile number in "XXX.XXX.XXXX" format
    const validatePhoneNumber = (value) => {
        const regex = /^\d{3}-\d{3}-\d{4}$/; // Regular expression for "XXX-XXX-XXXX" format
        if (regex.test(value)) {
            return {
                isValid: true,
                formatted: value, // The number is already in the correct format
            };
        } else {
            return {
                isValid: false,
                formatted: value,
            };
        }
    };

    const handleSelect = (key) => {

        let handleError = "";

        if (key === "contact") {
            if (!speakerFirstName || !speakerLastName || !speakerEmail || !bio) {
                handleError = 'All fields are required.';
            }
            if (bio.length > charLimit.bio) {
                handleError = `Bio should not exceed ${charLimit.bio} characters.`;
            }
        }

        if (key === "proposal") {
            if (!mobile || !jobTitle || !company || !companyType || !country || !primarySpeaker || !firstTime || !experience) {
                handleError = 'All fields are required.';
            }
        }

        if (key === "proposal") {
            if (!mobile || !jobTitle || !company || !country || !primarySpeaker || !firstTime || !experience) {
                handleError = 'All fields are required.';
            }
            const { isValid } = validatePhoneNumber(mobile);

            if (!isValid) {
                handleError = 'Mobile number is invalid.';
            }
        }

        if (key === "audience") {
            if (!proposalTitle || !proposalDesc || !proposalFormat || !learn1 || !learn2 || !learn3 || !experience) {
                handleError = 'All fields are required.';
            }
            if (proposalTitle.length > charLimit.proposalTitle) {
                handleError = `Proposal title should not exceed ${charLimit.proposalTitle} characters.`;
            }
            if (proposalDesc.length > charLimit.proposalDesc) {
                handleError = `Proposal description should not exceed ${charLimit.proposalDesc} characters.`;
            }
        }

        // Validation for required fields
        if (key === 'review') {
            if (!committee1 || !committee2 || !audienceRole || !backGround) {
                handleError = 'All fields are required.';
            }
        }

        if (key === "confirmation") {
            if (!speakerFirstName || !speakerLastName || !speakerEmail || !bio) {
                handleError = 'All fields are required.';
                setKey('speaker');
            } else {
                const proposalInfo = {
                    jobTitle,
                    speakerFirstName,
                    speakerLastName,
                    speakerEmail,
                    bio,
                    mobile,
                    company,
                    companyType,
                    country,
                    primarySpeaker,
                    onBehalfOf,
                    firstTime,
                    experience,
                    proposalTitle,
                    proposalDesc,
                    proposalFormat,
                    learn1,
                    learn2,
                    learn3,
                    committee1,
                    committee2,
                    audienceRole,
                    backGround,
                    submittedBy: isAuthenticated().userId
                }

                creatNewProposalSubmission(token, proposalInfo).then(response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        sendEmails();
                    }
                })
            }

        }

        if (handleError) {
            setError(handleError);
        } else {
            setError('');
            setKey(key);
        }

       
    };

    const handlePrimarySpeakerChange = (e) => {
        setPrimarySpeaker(e.target.value);
    };

    const handleChange = (setter) => (e) => {
        setter(e.target.value);
        setError('');
    };

    const showError = () => {
        return <div className="alert alert-danger">{error}</div>;
    };

    function sendEmails() {
        const mailMessage = {
            "subject": 'Your SDISC presentation proposal',
            "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "to": [{ "name": `${speakerEmail}`, "email": `${speakerEmail}` }],
            "htmlContent": `<html><body>Hello <b>${speakerFirstName}</b>, <p>Thank you for submitting a presentation proposal to the <b>SDSIC</b></p> 
                <p>Our leadership team is selecting presentations for our spring 2025 schedule now and will get back to your shortly.</p> 
                <p> Thank you!</p> <p>ps: Please be sure to check you junk or spam folders for emails. </p>
                <p>Your proposal submittal was as follows:</p>
                    <p>Proposal Title: <b>${proposalTitle}</b></p>
                    <p>Proposal Description: <b>${proposalDesc}</b></p>
                    <p>Proposal Format: <b>${proposalFormat}</b></p>
                    <p>Committee Choice 1: <b>${committee1}</b></p>
                    <p>Primary speaker is: <b>${onBehalfOf}</b><br/>
                        Speaker:<b>${speakerFirstName} ${speakerLastName}</b><br/>
                        Email: <b>${speakerEmail}</b><br/>
                        Bio: <b>${bio}</b>
                    </p>
                    <p><b>Presentation Experience:</b> ${experience}</p>
                    <p><b>Learning Objective 1:</b> ${learn1}</p>
                    <p><b>Learning Objective 2:</b> ${learn2}</p>
                    <p><b>Learning Objective 3:</b> ${learn3}</p>
                    <p><b>Target Audience:</b> ${audienceRole}</p>
                </body></html>`
        }

        // make request to api to send message
        sendMessage(mailMessage)

        const adminMessage = {
            "subject": 'Presentation Proposal Submitted',
            "sender": { "email": `${speakerEmail}`, "name": `${speakerEmail}` },
            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "to": [{ "name": "trevor@sdsconsortium.com", "email": "trevor@sdsconsortium.com" }],
            "htmlContent": `<html><body>From user ${speakerFirstName}<p>from company: <b>${company}</b>, 
                    has submitted a proposal from email: ${speakerEmail}}</p><p>As company Type: <b>${companyType}
                    </b></p>
                    <p>Proposal Title: <b>${proposalTitle}</b></p>
                    <p>Proposal Description: <b>${proposalDesc}</b></p>
                    <p>Proposal Format: <b>${proposalFormat}</b></p>
                    <p>Committee Choice 1: <b>${committee1}</b></p>
                    <p>Primary speaker is: <b>${onBehalfOf}</b><br/>
                        Speaker:<b>${speakerFirstName} ${speakerLastName}</b><br/>
                        Email: <b>${speakerEmail}</b><br/>
                        Bio: <b>${bio}</b>
                    </p>
                    <p><b>Presentation Experience:</b> ${experience}</p>
                    <p><b>Learning Objective 1:</b> ${learn1}</p>
                    <p><b>Learning Objective 2:</b> ${learn2}</p>
                    <p><b>Learning Objective 3:</b> ${learn3}</p>
                    <p><b>Target Audience:</b> ${audienceRole}</p>
                    </body></html>`
        }

        // Let the user know something is happening
        sendMessage(adminMessage)
    }

    return (
        <div className="container">
            <div className="text-center">
                <h2>Conference Presentation Proposal Submission</h2>
                <p>Please fill out the details of your presentation proposal for review.</p>
            </div>

            <Tab.Container activeKey={key} onSelect={handleSelect}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="speaker">Speaker Information</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="contact">Contact Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="proposal">Proposal Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="audience">Audience Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="review">Review Submission</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="confirmation">Confirmation</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="speaker">
                                <div className="s-box">
                                    {error && showError()}
                                    <Form>
                                        <Form.Group controlId="formSpeakerFirstName">
                                            <Form.Label>Account First Name</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                placeholder="Enter speaker's first name"
                                                value={speakerFirstName}
                                                onChange={handleChange(setSpeakerFirstName)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formSpeakerLastName">
                                            <Form.Label>Speaker Last Name</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                placeholder="Enter speaker's last name"
                                                value={speakerLastName}
                                                onChange={handleChange(setSpeakerLastName)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formSpeakerEmail">
                                            <Form.Label>Speaker Email</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="email"
                                                placeholder="Enter speaker's email"
                                                value={speakerEmail}
                                                onChange={handleChange(setSpeakerEmail)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBio">
                                            <Form.Label>Speaker Bio</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter a brief speaker bio"
                                                value={bio}
                                                onChange={handleChange(setBio)}
                                            />
                                        </Form.Group>
                                        <Button variant="success" onClick={() => handleSelect("contact")}>Next</Button>
                                    </Form>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="contact">
                                <div className="s-box">
                                    {error && showError()}
                                    <Form>
                                        <Form.Group controlId="formMobile">
                                            <Form.Label>Mobile Number '(format must be "XXX-XXX-XXXX")'</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter mobile number"
                                                name="mobile"
                                                value={mobile}
                                                onChange={handleChange(setMobile)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formJobTitle">
                                            <Form.Label>Job Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Job title"
                                                value={jobTitle}
                                                onChange={handleChange(setJobTitle)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formCompany">
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Company"
                                                value={company}
                                                onChange={handleChange(setCompany)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formCompanyType">
                                            <Form.Label>Please select the appropriate company type from this list:</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={companyType}
                                                onChange={handleChange(setCompanyType)}
                                            >
                                                <option value="">Choose ...</option>
                                                <option value="utility">Utility</option>
                                                <option value="services">Services</option>
                                                <option value="vendor">Vendor</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formCountry">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country"
                                                value={country}
                                                onChange={handleChange(setCountry)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formPrimarySpeaker">
                                            <Form.Label>Will you be the primary speaker or are you submitting on behalf of someone else?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={primarySpeaker}
                                                onChange={handlePrimarySpeakerChange}
                                            >
                                                <option value="">Choose ...</option>
                                                <option value="self">I will be the Primary Speaker</option>
                                                <option value="other">I am submittin on behalf of someone else</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {primarySpeaker === 'other' && (
                                            <Form.Group controlId="formOnBehalfOF">
                                                <Form.Label>Since you are submitting on behalf of someone else, provide the primary speaker details. Format: Full Name, job title, company</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    placeholder="Primary Speaker full name, title, company"
                                                    value={onBehalfOf}
                                                    onChange={(e) => setOnBehalfOf(e.target.value)}
                                                />
                                            </Form.Group>
                                        )}
                                        {primarySpeaker === 'self' && (
                                            <>
                                                <Form.Group controlId="prim1">
                                                    <Form.Label>Speaker's first name: </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        value={speakerFirstName}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="prim2">
                                                    <Form.Label>Speaker's last name: </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        value={speakerLastName}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="prim2">
                                                    <Form.Label>Speaker's email: </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        value={speakerEmail}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}
                                        <Form.Group controlId="formFirstTime">
                                            <Form.Label>Is this the primary speakers first time presenting at the SDSIC conference?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={firstTime}
                                                onChange={handleChange(setFirstTime)}
                                            >
                                                <option value="">Choose ...</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formExperience">
                                            <Form.Label>Tell us about the primary speaker's experience presenting.</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={experience}
                                                onChange={handleChange(setExperience)}
                                            >
                                                <option value="">Choose ...</option>
                                                <option value="new">New to presenting</option>
                                                <option value="onetofour">Presented 1 to 4 times at the SDSIC</option>
                                                <option value="fiveormore">Presented 5+ times at the SDSIC</option>
                                                <option value="many">Presented at many other conferences</option>
                                                <option value="notsure">I'm not sure</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Button variant="primary" onClick={() => handleSelect("speaker")}>Back</Button>
                                        <Button variant="success" onClick={() => handleSelect("proposal")}>Next</Button>
                                    </Form>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="proposal">
                                <div className="s-box">
                                    {error && showError()}
                                    <Form>
                                        <Form.Group controlId="formProposalTitle">
                                            <Form.Label>Proposal title: As you would like it to appear in the schedule (Max length 100 characters).</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter presentation title"
                                                value={proposalTitle}
                                                onChange={handleChange(setProposalTitle)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formProposalDesc">
                                            <Form.Label>Proposal description: As displayed in schedule (Max length 400 characters).</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                placeholder="Write a one or two paragraph abstract describing your proposal idea. No bullets or numbered lists."
                                                value={proposalDesc}
                                                onChange={handleChange(setProposalDesc)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formProposalFormat">
                                            <Form.Label>Proposal Format</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={proposalFormat}
                                                onChange={handleChange(setProposalFormat)}
                                            >
                                                <option>Choose...</option>
                                                <option>Case study</option>
                                                <option>Informational</option>
                                                <option>Hands-On</option>
                                                <option>Forum</option>
                                                <option>Developmental Idea</option>
                                                <option>On the Horizon Presentation</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formLearn1">
                                            <Form.Label>Learning objective 1.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Describe what SDSIC members will learn."
                                                value={learn1}
                                                onChange={handleChange(setLearn1)}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formLearn2">
                                            <Form.Label>Learning objective 2.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Describe what SDSIC members will learn."
                                                value={learn2}
                                                onChange={handleChange(setLearn2)}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formLearn3">
                                            <Form.Label>Learning objective 3.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Describe what SDSIC members will learn."
                                                value={learn3}
                                                onChange={handleChange(setLearn3)}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={() => handleSelect("contact")}>Back</Button>
                                        <Button variant="success" onClick={() => handleSelect("audience")}>Next</Button>
                                    </Form>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="audience">
                                <div className="s-box">
                                    {error && showError()}
                                    <Form>
                                        <Form.Group controlId="formCommittee">
                                            <Form.Label>Choose your target committee track for this proposal</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={committee1}
                                                onChange={handleChange(setCommittee1)}
                                            >
                                                <option>Choose...</option>
                                                <option>Business Strategy</option>
                                                <option>Civil</option>
                                                <option>Electrical</option>
                                                <option>Innovative Solutions</option>
                                                <option>Physical</option>
                                                <option>Technology</option>
                                                <option>On the Horizon Event</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formCommittee2">
                                            <Form.Label>If applicable, what would be your second choice?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={committee2}
                                                onChange={handleChange(setCommittee2)}
                                            >
                                                <option>Choose...</option>
                                                <option>Business Strategy</option>
                                                <option>Civil</option>
                                                <option>Electrical</option>
                                                <option>Innovative Solutions</option>
                                                <option>Physical</option>
                                                <option>Technology</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formAudienceRole">
                                            <Form.Label>Define your primary audience role?</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={audienceRole}
                                                onChange={handleChange(setAudienceRole)}
                                            >
                                                <option>Choose...</option>
                                                <option>Business leaders</option>
                                                <option>Newbie</option>
                                                <option>Seasoned design specialist</option>
                                                <option>General solution sharing</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formBackGround">
                                            <Form.Label>Describe any prerequisite skills or knowledge, including a brief description of who will benefit from attending
                                                your session (consider disciplines, job titles, specialities, interests, etc.).</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Describe what SDSIC members will learn."
                                                value={backGround}
                                                onChange={handleChange(setBackGround)}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={() => handleSelect("proposal")}>Back</Button>
                                        <Button variant="success" onClick={() => handleSelect("review")}>Next</Button>
                                    </Form>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="review">
                                <div className="s-box">
                                    <h3>Review Your Submission</h3>
                                    <p><b>Title:</b> {proposalTitle}</p>
                                    <p><b>Abstract:</b> {proposalDesc}</p>
                                    <p><b>Format:</b> {proposalFormat}</p>
                                    <p><b>Committee:</b> {committee1}</p>
                                    {primarySpeaker === 'other' && (<p><b>Speaker:</b> {onBehalfOf}</p>)}
                                    {primarySpeaker === 'self' && (
                                        <>
                                            <p><b>Speaker:</b> {speakerFirstName} {speakerLastName}</p>
                                            <p><b>Email:</b> {speakerEmail}</p>
                                            <p><b>Bio:</b> {bio}</p>
                                        </>
                                    )}
                                    <p><b>Presentation Experience:</b> {experience}</p>
                                    <p><b>Learning Objective 1:</b> {learn1}</p>
                                    <p><b>Learning Objective 2:</b> {learn2}</p>
                                    <p><b>Learning Objective 3:</b> {learn3}</p>
                                    <p><b>Target Audience:</b> {audienceRole}</p>
                                    <Button variant="primary" onClick={() => handleSelect("audience")}>Back</Button>
                                    <Button variant="success" onClick={() => handleSelect("confirmation")}>Submit</Button>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="confirmation">
                                <div className="s-box">
                                    <h3>Submission Successful!</h3>
                                    <p>Thank you for your proposal submission. We will review your presentation and contact you shortly.</p>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}

export default ConferencePresentationSubmission;
