import React from 'react';
import './Highlights.css';

function Highlights() {
  return (
    <section className="highlights" id="highlights">
      <h2>Event Highlights</h2>
      <div className="highlight-cards">
        <div className="highlight-card">
          <h3>Top Speakers</h3>
          <p>Hear from industry-leading experts in substation design, engineering, and technology.</p>
        </div>
        <div className="highlight-card">
          <h3>Networking</h3>
          <p>Connect with peers, share best practices, and forge new partnerships.</p>
        </div>
        <div className="highlight-card">
          <h3>Workshops & Tours</h3>
          <p>Learn hands-on from solution providers and see real-world applications.</p>
        </div>
      </div>
    </section>
  );
}

export default Highlights;
