import React from 'react';
import './Navbar.css'; // optional for your nav styling

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">SDSIC 2025 Event</div>
      <ul className="navbar-menu">
        <li><a href="#highlights">Highlights</a></li>
        <li><a href="#event-details">Event Details</a></li>
        <li><a href="#join-us">Join Us</a></li>
        <li><a href="#footer">Contact</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
