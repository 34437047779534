import React from 'react';
import './EventDetails.css';

function EventDetails() {
  return (
    <section className="event-details" id="event-details">
      <h2>Event Details</h2>
      <div className="details-grid">
        <div>
          <p><strong>When:</strong> April 28 – May 1, 2025</p>
          <p><strong>Where:</strong> The Westin Chattanooga</p>
        </div>
        <div>
          <p><strong>Hosted By:</strong> Mesa Associates, Inc.</p>
          <p><strong>Pricing:</strong> Early Bird $520 (through Jan 17)</p>
        </div>
      </div>
      <a href="/registration25" className="register-button">Register Today</a>
    </section>
  );
}

export default EventDetails;
