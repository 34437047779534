import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./core/Home";
import Menu from "./core/Menu";
import Signup from "./user/Signup";
import SignupPending from "./user/SignupPending";
import Signin from "./user/Signin";
import PublicHome from "./core/PublicHome";
import PublicMemberLogos from "./core/PublicMemberLogos";
import Profile from "./user/Profile";
import Users from "./user/Users";
import EditProfile from "./user/EditProfile";
import Proposals from "./event/proposals";
import ConferencePresentationSubmission from "./event/ConferencePresentationSubmission.js";
import FindPeople from "./user/FindPeople";
import NewPost from "./post/NewPost";
import NewForumPost from "./forum/NewForumPost";
import EditForumPost from "./forum/EditForumPost"
import SingleForumPost from "./forum/SingleForumPost"
import EditPost from "./post/EditPost";
import SinglePost from "./post/SinglePost";
import PrivateRoute from "./auth/PrivateRoute";
import ForgotPassword from "./user/ForgotPassword";
import ResetPassword from "./user/ResetPassword";
import Admin from "./admin/Admin";
import AdminGroup from "./admin/AdminGroup";

import GroupAccess from "./group/GroupAccess";
import groupList from "./group/GroupList";
import SingleGroup from "./group/SingleGroup";

import CreateFile from "./file/CreateFile";
import CreateEvent from "./event/CreateEvent";
import AllEvents from "./event/AllEvents";
import SingleEvent from "./event/singleEvent";
import ShowCaseEvent from "./event/ShowCaseEvent";
import Presentation from "./event/Presentation";


import PublicAbout from "./core/PublicAbout";
import PublicEvents from "./core/PublicEvents";
import PolicyStatement from './core/PolicyStatement';

import Survey from "./core/Survey";
import Survey2022 from "./core/Survey2022";
import ContentSurvey2022 from "./core/ContentSurvey2022";
import Members from "./core/Members";

import Sping2021eventRegReport from './event/EventRegReport';
import publicNashvilleClosed from './core/publicNashvilleClosed';
import publicVirtual23 from './core/publicVirtual23';

// import publicCallforPapers from './core/callforPapers';

// import nashvilleSponsorReg from './event/nashvilleSponsorReg';
import publiceventKC_original from './core/publiceventKC_original'
import kansasCityEvent from './event/kansasClosed';
import publicKansasCityEvent from './core/publicKansasClosed';
import publicMesaEvent from './core/publicMesa25';
import publicMesaEventDetailed from './core/publicMesas25details';

import OurSponsors from './core/OurSponsors';
import publiceventsping2022 from './core/publiceventsping2022';
// import PublicFall2021Event from './core/PublicFall2021Event';
import PublicSpringEvent2022 from './core/PublicSpringEvent2022';
import publicNashvillePhotos from './core/publicNashvillePhotos';

import Awards from './core/Awards';
import sdsicmerch from './user/sdsicmerch';
import MerchDetail from './user/merchdetail';
import MerchCheckOut from './user/merchCheckOut';


// import NashvilleRegistration from './event/NashvilleRegistration';
import KansasRegistration from './event/KansasRegistration'
import KansasSponsorReg from './event/kansasSponsorReg'
import Registration25 from "./event/2025Registration";
import PresenterReg25 from "./event/PresenterReg25.js";

import Fall2022Schedule from './event/event2022schedule';

import FallSponsorRegistration from './event/FallSponsorRegistration';
import eventFallVirtual2023 from './event/eventFallVirtual2023';

import Podcast from './core/Podcast';

import { Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

// import SuperAdminHome from './superAdmin/superadminhome'

// import { signout, isAuthenticated } from './auth';

import Privacy from './core/PrivacyStatement';
import Terms from './core/TermsStatement';

import eventDemo from './event/EventDemo';
import eventSim from './event/eventSim';
import SponsorReg25 from "./event/SponsorReg25";


const MainRouter = () => (
  <Elements stripe={loadStripe('pk_test_Rsw8DhHJSiqhyGWrbqoN43p7')}>
  {/* <Elements stripe={loadStripe(process.env.STRIPE_SECRET)}> */}
    <div className="container">
      <header className="blog-header py-3">
        <div className="row flex-nowrap justify-content-between align-items-center">
          <div className="col-4 pt-1">            
            <a href="/usershome"><img src={process.env.PUBLIC_URL + '/SDSLogo10Year.png'} style={{ height: "150px"}} alt="SDSIC"></img></a>
          </div>  
          <div className="col-8">
            <h4><a className="blog-header-logo text-dark" href="/About">"Design. Integrate. Automate."</a></h4>
          </div>
       
        </div>
      </header>
        <Menu />
        <br/>
        <Switch>
 
            <PrivateRoute exact path="/usershome" component={Home} />
            
            <Route exact path="/sdsicmerch" component={sdsicmerch} />
            <Route exact path="/merchDetail/:articleId" component={MerchDetail} />
            <Route exact path="/merchCheckOut/:articleId" component={MerchCheckOut} />
            <PrivateRoute exact path="/publicNashvillePhotos" component={publicNashvillePhotos} />
            
            <Route exact path="/" component={PublicHome} />
            <Route exact path="/memberLogos" component={PublicMemberLogos} />
            <Route exact path="/about" component={PublicAbout} />
            <Route exact path="/proposals" component={Proposals} />
            <PrivateRoute exact path="/ConferencePresentationSubmission" component={ConferencePresentationSubmission} />
            
            <Route exact path="/awards" component={Awards} />
            <Route exact path="/events" component={PublicEvents} />
            <Route exact path="/podcast" component={Podcast} />

            {/* <Route exact path="/event2020" component={Public2020Event} /> */}
            
            {/* <Route exact path="/fall2021event" component={NewSpring2021Event} /> */}
            {/* <Route exact path="/spring2022event" component={Spring2022Event} /> */}

            <Route exact path="/publicNashvilleClosed" component={publicNashvilleClosed} />
            <Route exact path="/publicVirtual23" component={publicVirtual23} />
            {/* <Route exact path="/nashvilleSponsorReg" component={nashvilleSponsorReg} /> */}
            
            <Route exact path="/publicKansasCityEvent" component={publicKansasCityEvent} />
            <Route exact path="/publicMesaEvent" component={publicMesaEvent} />
            <Route exact path="/publicMesa25detail" component={publicMesaEventDetailed} />
            

            {/* <Route exact path="/publicCallforPapers" component={publicCallforPapers} /> */}
            <Route exact path="/publicCallforPapers" component={publicKansasCityEvent} />
            

            <Route exact path="/publiceventKC_original" component={publiceventKC_original} />
            <Route exact path="/publiceventsping2022" component={publiceventsping2022} />
            <Route exact path="/sponsors" component={OurSponsors} />
            
            <PrivateRoute exact path="/fallVirtual23" component={eventFallVirtual2023} />
            <PrivateRoute exact path="/SpringEvent2022Calendar" component={PublicSpringEvent2022} />
            <PrivateRoute exact path="/kansasCityEvent" component={kansasCityEvent} />
            <Route exact path="/spring2021Regreport" component={Sping2021eventRegReport} />
            <Route exact path="/survey" component={Survey} />
            <Route exact path="/survey2022" component={Survey2022} />
            <Route exact path="/contentsurvey2022" component={ContentSurvey2022} />
            <Route exact path="/members" component={Members} />
            <PrivateRoute exact path="/admin" component={Admin} />

            <PrivateRoute exact path="/admin/committee/:groupId" component={AdminGroup} />

            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:resetPasswordToken" component={ResetPassword}/>
            
            <PrivateRoute exact path="/group/requestaccess/:groupId" component={GroupAccess} />

            <PrivateRoute exact path="/post/create/:groupId" component={NewPost} />
            <PrivateRoute exact path="/forumpost/create/:groupId" component={NewForumPost} />
            <PrivateRoute exact path="/forumpost/edit/:forumpostId" component={EditForumPost} />

            <Route exact path="/post/:postId" component={SinglePost} />
            <Route exact path="/forumpost/:forumpostId" component={SingleForumPost} />      

            {/* <Route exact path="/linkedin" component={LinkedInPopUp} />             */}

            <PrivateRoute
                exact
                path="/post/edit/:postId"
                component={EditPost}
            />
            <Route exact path="/users" component={Users} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/signuppending" component={SignupPending} />
            <Route exact path="/signin" component={Signin} />

            <Route exact path="/privacystatement" component={Privacy} />
            <Route exact path="/policystatement" component={PolicyStatement} />
            <Route exact path="/termsstatement" component={Terms} />
            
            <PrivateRoute
                exact
                path="/user/edit/:userId"
                component={EditProfile}
            />
            <PrivateRoute exact path="/findpeople" component={FindPeople} />
            <PrivateRoute exact path="/user/:userId" component={Profile} />
            
            <PrivateRoute exact path="/grouplist" component={groupList} />
            <PrivateRoute exact path="/singlegroup/:groupId" component={SingleGroup} />            

            <PrivateRoute exact path="/file/create/:groupId" component={CreateFile} />
            <PrivateRoute exact path="/event/create/:groupId" component={CreateEvent} />
            <PrivateRoute exact path="/allevents" component={AllEvents} />

            <PrivateRoute exact path="/singleEvent/:eventId" component={SingleEvent} />
            <PrivateRoute exact path="/showcaseEvent/:eventId" component={ShowCaseEvent} />

            <PrivateRoute exact path="/presentation/:presentationId" component={Presentation} />
            
            {/* <Route exact path="/linkedin" component={LinkedInPopUp} />      */}
            
            {/* <Route exact path="/superadmin" component={SuperAdminHome} />     */}

             <PrivateRoute exact path="/eventDemo" component={eventDemo} />    

             {/* <PrivateRoute exact path="/eventsimulation" component={eventSim} />     */}

             {/* <PrivateRoute exact path="/fallregistration" component={FallRegistration} /> */}
             {/* <PrivateRoute exact path="/NashvilleRegistration" component={NashvilleRegistration} /> */}

             <PrivateRoute exact path="/registration25" component={Registration25} />
             <PrivateRoute exact path="/presenterReg25" component={PresenterReg25} />

             <Route exact path="/sponsorreg25" component={SponsorReg25} />
             
             <Route exact path="/fall2022schedule" component={Fall2022Schedule} />
             
             <PrivateRoute exact path="/fallSponsorRegistration" component={FallSponsorRegistration} />    
            

        </Switch>
    </div> 
    </Elements>
);

export default MainRouter;