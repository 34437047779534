/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import Container from 'react-bootstrap/Container';
import { Container, Box, Paper, Grid, Typography, Stack } from '@mui/material';


// import Posts from "../post/Posts";
import Footer from '../core/Footer';

const Podcast = () => (
    <>
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={6}>
                        <img
                            src="/images/PowerUp.jpg"
                            loading="lazy"
                            height="300"
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Typography variant="h4" component="div" gutterBottom>
                            the PowerUp podcast
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            by <b>Trevor Scullion</b>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We interview SDSIC members about exciting projects they're working on and
                            learn how they're creating new solutions to address the growing needs of our Power Grid.
                            In each episode, members share deep, creative thoughts and share insights on their current
                            efforts and projects. PowerUp is a master-class on Electric Utility work, creativity,
                            leadership and how to support grid advancements.
                        </Typography>
                    </Grid>

                </Grid>
            </Box>
            <hr />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={5}>
                        <Typography variant="h4" component="div" gutterBottom>
                            Latest Episodes
                        </Typography>
                    </Grid>
                    <Grid item lg={7}>
                        <Stack direction="row" spacing={2}>

                            <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS5yc3MuY29tL3Bvd2VydXAvZmVlZC54bWw=" target="_blank">
                                <img
                                    src="/images/googlepdcatListen.JPG"
                                    height="45px"
                                />
                            </a>
                            <a href="https://music.amazon.com/podcasts/8e1489d0-b0af-4c21-8275-ec5e38bf0bf3/powerup-conversations-with-sdsic-members" target="_blank">
                                <img
                                    src="/images/amazonmusiclisten.JPG"
                                    height="45px"
                                />
                            </a>
                            <a href="https://open.spotify.com/show/2ZteqWzcKnb6Q9fawWsA6O" target="_blank">
                                <img
                                    src="/images/SpotifyListen.JPG"
                                    height="45px"
                                />
                            </a>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <hr />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 21
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        This is the second episode of our three episode series on Artificial intelligence in the Electric Utility industry and special SDSIC members Michael Bussinger,
                        Cory Byrnes, Anthony Contino, Ben Ferneau, Josue Sanchez, and Shawn Weekly joins us for some incedible conversations around AI.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1845093/" 
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1845093/">2025: Episode 2 of our 3 episode series on AI in the electric utility space | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 20
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        This is the first episode of our three episode series on Artificial intelligence in the Electric Utility industry and special SDSIC members Michael Bussinger,
                        Cory Byrnes, Anthony Contino, Ben Ferneau, Josue Sanchez, and Shawn Weekly joins us for some incedible conversations around AI.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1783089/" 
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1783089/">2024: Episode 1 of our 3 episode series on AI in the electric utility space. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 19
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        Katie Muer from Black and Veatch joins Scott Dimpfl, our SDSIC Vice Chair and myself so we can chat about this year's amazing SDSIC live conference
                        in Kansas City that Black & Veatch hosted! We talk about what it takes to host an event, Katie offers some advice and Scott runs the Q&A! Listen in!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1538739/" 
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1538739/">2024: Katie Muer from Black & Veatch and Scott Dimpfle our SDSIC Vice Chair! | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 18
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        Cory Byrnes from Beta Engineering joins me and we chat about his Civil and Structural engineering work at BETA, his newly minted 
                        membership in the SDSIC, advice for other SDSIC newbies, why your should get involved, and of course ... we talk technology and AI.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1525081/" 
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1525081/">2024: Cory Byrnes from Beta Engineering! | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 17
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        In this episode we close out 2023 by pulling some tidbits of advice on the SDSIC from each conversation through the 2023 year.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1270569/"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1270569/">2023: The year in review! | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 16
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Scott Dimpfl is Director of Transmission Line Engineering at AEP and our current Chair for the Technology committee. With over 20 years of experience,  
                            Scott has some in depth knowledge to share and we explore some of what he's championing at AEP these days.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/1084474/"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/1084474/">Scott Dimpfl from AEP | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 15
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Jack Hopson is a Technical Solutions Executive for the Utilities Industries at Autodesk and has been with our SDSIC community for many years now. He has an interesting 
                            perspective on our industry having worked at Ameren and for several technology firms over the years both implementing and delivering solutions.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/995196"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/995196/">Jack Hopson from Autodesk | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 14
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Michael Goins is a Senior Project Engineer at POWER Engineers today and 10 years ago this year, he was the very first SDSIC chair elected. His early work with the SDSIC 
                            was instrumental in setting things up to drive the success we have today.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/906553"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/906553/">Michael Goins from POWER Engineers | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 13
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Michael joined me to talk about his work as our SDSIC co-Chair for the Civil Structural committee. We also talked about the work he does 
                            at Burns & McDonnell and, as an Adjunct Instructor at Metropolitan Community College-Maple Woods where we discover that Michael 
                            has a true passion for teaching others. Have a listen!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/893731"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/893731/">Michael Bussinger from Burns & McDonnell | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 12
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                         Dennis is the co-founder and CEO of SBS, the tech company behind the AUD and SDS product suites used 
                         by so many members of the SDSIC. Dennis chats with me about his company, the team and all the software
                          products. We even step into some conversation about future tech and some excellent advice for 
                          SDSIC Members.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/884784"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/884784/">Dennis Beck co-founder and CEO from SBS joins me for a chat | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 11
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Michael joined me to chat about the SDSIC, 3D technology new and old, different ways in which designers can embrace 3D technology and where he sees the industry headed! Have a listen!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/873984"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/873984/">Michael Bruce co-founder of 3D Transition Services | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 10
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Duane is the Technology Partner Director at POWER Engineers and has been around the SDSIC since the concept was first discussed. He has helped us plan and grow since then and continues that same support today. He joins me and we talk about his interesting history in this industry and lots of SDSIC talk. Enjoy!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/868072"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/868072/">Duane Guidry joins me to talk about the SDSIC | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 9
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Mark is our current SDSIC 2023 co-chair and has been a long standing member having run the technology committee as chair and co-chair over the years. We had a great talk about our consortium, technology, advice on how to run committees and even some great advice for new members joining us for the first time. Have a listen!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/864594"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/864594/">Mark Nelson from Idaho Power | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 8
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Shawn is a Digital Transformation Architect at POWER Engineers and one of the earliest members of the SDSIC having been the technology chair and now the co-chair of the New Innovative Solutions committee. We chat about his background with the SDSIC, technology, and the power industry as a whole!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/854441"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/854441/">Shawn Weekly stops by for a chat! | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 7
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                        Josh is the current Chair of the SDSIC Physical Committee and a Design Drafter at PPL Electric Utilities. He has been running the committee for over two terms now and he joins me to chat about how he got started in design, his career path, CAD technology past, present and future, and he offers up some great advice for members new to the consortium.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/846231"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/846231/">Josh Clark from PPL Electric Utilities | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 6
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Jeremy is one of the original founders of the SDSIC and has held more seats than any other member having started the Physical committee, held the SDSC Chair for a few years and now as the co-chair of the Business Strategy committee. On this episode, we talk about his history, technology and how he sees things progressing in the future. Have a listen!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/840391"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/840391/">Jeremy Morton from POWER Engineers | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 5
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Ernie has started up a brand new group for the SDSIC, the Innovative Solutions Committee. His current role as the Information Delivery Tools supervisor in the Business Process and Innovations Groups and his experience make him the perfect choice to head up this new group. Listen in and learn more!
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/833692"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/833692/">Ernie Barton from Southern Company stops by for a chat. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 4
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Justin is the current Chair of the SDSIC Electrical Committee and a Senior Engineering Technologist / ACADE SME at Duke Energy. He joins us to chat about how he got started in design, his career path and he offers up some great advice for members new to the consortium and even for those new to design.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/825498"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/825498/">Just Martin from Duke Energy joins us for a chat. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 3
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Bill is a founding member of the SDSIC even coining the tagline "Design. Integrate. Automate." for us early on. He has led the Electrical committee in the past and now heads up the Business Strategy Committee. We chat about his career, goals for his committee and he provides some valuable advice for new members.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/820361"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/820361">Bill Gordon From Southern Company chats with us. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 2
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Audrey Marich is currently, and has been the SDSIC Chair for the past three years. She has grown our membership to 1,700 members and guided us through the pandemic via virtual conferences and zoom meetings. Join us as we discuss the SDSIC charter changes, what's in store for the 10th anniversary and tips for new members just getting involved.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/806855"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/806855/">Audrey Marich from PPL Electric Utility Joins us. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
            <br />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Episode 1
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            We kick off our podcast with long time member, Jeff Spence, VP of Strategic Innovations at Ampirical Solutions. He joins
                            us to talk about 10 years of the SDSIC, tips on getting started with intelligent design, how to get the most our of
                            your SDSIC peers and we even touch on cyber security.
                        </Typography>
                        <iframe src="https://player.rss.com/powerup/803721"
                            style={{ width: "100%" }}
                            title="PowerUp: Conversations with SDSIC Members"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                            <a href="https://rss.com/podcasts/powerup/803721/">Jeff Spence from Ampirical joins us. | RSS.com</a>
                        </iframe>
                    </Grid>

                </Grid>
            </Box>
          
            
        </Container>
        <br />
        <br />

        <Footer />

    </>
);

export default Podcast; 