import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

class MonthlyMeetingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meetingInvite: '',
            groupId: this.props.groupId
        };
    }
   
   // https://sdsicinvites.s3.amazonaws.com/business%2Bstrategy%2Bmonthly%2Bmeeting%2Binvite_24.ics
    setMeetingInvite(groupId) {
        switch (groupId) {
            case '5e2a5e764175b97d84ec6cae':
                //physical
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/hysical_committee_monthly_meeting_Invite_Outlook_25.ics">Download the Physical Committee Monthly Meeting Outlook Invite for all of 2025</a>
            case '5e44050f4e6ed37ab467d0f2':
                //electrical
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/Electrical%2BCommittee%2BMonthly%2BOutlook%2BInvite_25.ics">Download the Electrical Committee Monthly Meeting Outlook Invite for all of 2025</a>
            case '5e44052e4e6ed37ab467d0f3':
                //Technology
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/technology%2Bcommitee%2Bmonthly%2Bmeeting%2Binvite_25.ics">Download the Technology Committee Monthly Meeting Outlook Invite for all of 2025</a>       
            case '5e4405864e6ed37ab467d0f4':
                //civil
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/civil%2Bcommitttee%2Bmonthly%2Bmeeting%2Binvite_25.ics">Download the Civil Committee Monthly Meeting Outlook Invite for all of 2025</a>       
            case '5e4406454e6ed37ab467d0f5':
                //bizstrategy
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/business%2Bstrategy%2Bmonthly%2Bmeeting%2Binvite_25.ics">Download the Business Strategy Committee Monthly Meeting Outlook Invite for all of 2025</a>
            case '5e5591a97ff73f6d34835a05':
                // leadership
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/Leadership%2Bcommitee%2Bmonthly%2Bmeeting%2Binvite_25.ics">Download the Leadership Commitee monthly meeting Outlook Invite for all of 2025</a>
            case '639242d4971a30726884fd85' :
                // Innovation committee
                return <a href="https://sdsicinvites.s3.us-east-1.amazonaws.com/InnovationtsCommitee_monthly_meeting_25.ics">Download the Innovationts Commitee Monthly Meeting Outlook Invite for all of 2025</a>
            default :
                return '';                           
          };
    }

    
    setGroupName(groupId) {
        switch (groupId) {
            case '5e2a5e764175b97d84ec6cae':
                //physical
                return 'Physical Committee';
            case '5e44050f4e6ed37ab467d0f2':
                //electrical
                return 'Electrical Committee';
            case '5e44052e4e6ed37ab467d0f3':
                //Technology
                return 'Technology Committee';
            case '5e4405864e6ed37ab467d0f4':
                //civil
                return 'Civil Committee';
            case '5e4406454e6ed37ab467d0f5':
                //bizstrategy
                return 'Business Strategy Committee';
            case '5e5591a97ff73f6d34835a05':
                // leadership
                return 'Leadership Committee';
            case '639242d4971a30726884fd85' :
                // Innovation committee
                return 'Innovation Committee';
            default :
                return '';                           
          };
    }


    render() {
        const { groupId, meetingInvite } = this.state;
        return (
            <>
                <h4 className="font-italic">2025 Monthly Meeting Invite</h4>
                <p>
                    {`${this.setGroupName(groupId)} Monthly Zoom Meeting`}
                </p>
                <p>
                    The Outlook meeting invite is available for download below.
                </p>
                {this.setMeetingInvite(groupId)}
                <hr/>
                
            </>
        )
    };
};

export default MonthlyMeetingList;