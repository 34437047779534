import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Automationforce from '../images/AF_Logo.png';
import { sendMessage } from '../group/apigroup';

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            emailSent: false,
            emailError: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (val) => {
        this.setState({ email: val });
    }

    handleSubmit(event) {
        // event.preventDefault(); // 👈️ prevent page refresh

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(this.state.email)) {
            const mailMessage = {
                "subject": "SDSIC Newsletter request",
                "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                "to": [{ "name": "Trevor", "email": "trevor@sdsconsortium.com" }],
                "htmlContent": `<html><body><p>add email: ${this.state.email} to the newsletter list.</p></body></html>`
            }

            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    emailSent: true,
                    emailError: false
                });
            });
        }
        else {
            this.setState({emailError: true })
        }

    };

    render() {
        return (
            <>
                <footer style={{ background: "#212529" }} className="w-100 py-4 flex-shrink-0">
                    <div className="container py-4">
                        <div className="row gy-4 gx-5">
                            <div className="col-lg-4 col-md-6">
                                <h5 className="h1 text-white"><img src={Automationforce} height={'75px'} alt="https://automationforce.com" /></h5>
                                <p className="small text-muted">This web platform is proudly built and maintained by <a className="text-primary" href="https://automationforce.com" target="_blank">Automationforce.com</a></p>
                                <p className="small text-muted mb-0">&copy; Copyrights. All rights reserved. </p>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h5 className="text-white mb-3">Quick links</h5>
                                <ul className="list-unstyled text-muted">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about">About Us</a></li>
                                    {/* <li><a href="/sponsors">Our Sponsors</a></li> */}
                                    <li><a href="/awards">Our Awards</a></li>
                                    <li><a href="/podcast">PowerUp Podcast</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h5 className="text-white mb-3">Quick links</h5>
                                <ul className="list-unstyled text-muted">
                                    <li><a href="/grouplist">Committees</a></li>
                                    <li><a href="/findpeople">Find Members</a></li>
                                    <li><a href="/allevents">Committee Events</a></li>
                                    <li><a href="https://www.linkedin.com/company/sds-industry-consortium" target="_blank">LinkedIn Page</a></li>

                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h5 className="text-white mb-3">Newsletter</h5>
                                <p className="small text-muted">Sign up for our newsletter and stay informed of upcoming events.</p>
                                <form>
                                    <div className="input-group mb-3">
                                        <input
                                            id="email"
                                            className="form-control"
                                            type="text"
                                            value={this.state.email}
                                            placeholder="Recipient's email"
                                            aria-label="Recipient's email"
                                            aria-describedby="button-addon2"
                                            onChange={event => this.handleChange(event.target.value)}
                                        />
                                        <button onClick={this.handleSubmit} className="btn btn-primary" id="button-addon2" type="button"><i className="fas fa-paper-plane"></i></button>
                                    </div>
                                </form>
                                {this.state.emailSent && <p className="alert alert-info">Thank you, your email has been added to our newsletter!</p>}
                                {this.state.emailError && <p className="alert alert-info">There was a problem with your email. Please verify.</p>}
                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }
}


export default withRouter(Footer);
