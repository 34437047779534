import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer" id="footer">
      <p>© 2025 SDSIC</p>
      <p>
        <a href="mailto:info@sdsconsortium.com">info@sdsconsortium.com</a>
      </p>
    </footer>
  );
}

export default Footer;
